.AdminDashboard {
  width: 55px;
  position: fixed;
  overflow-y: auto;
  height: calc(100% - 100px);
  z-index: 1;
  top: 100px;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  padding-top: 10px;
  background: var(--color-gray-900);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    li {
      a {
        span {
          color: var(--color-white) !important;
          font-feature-settings: 'case' on;
          font-family: var(--font-base);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0 10px;
          transition: all 0.3s ease;
          //padding: 7px 31px 7px 9px;
          border-radius: 8px;
          &:hover {
            background: #c53318;
            width: 100%;
          }
        }
      }
    }
  }
}
