.AppBlogInnerDetails {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--color-gray-900);
  border-radius: 21px;
  box-shadow: 0px 0px 10.52394px 0px rgba(0, 0, 0, 0.04);
  padding: 32px 20px;

  &__title {
    color: var(--color-white);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: var(--font-base);
    font-size: var(--font-size--md-display);
    font-style: normal;
    font-weight: var(--font-weight-medium);
    line-height: 36.834px; /* 153.474% */
    letter-spacing: 0.658px;
    padding-bottom: 12px;
    margin: 0;
  }

  &__article {
    color: var(--color-white);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: var(--font-base);
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: 0%;
    letter-spacing: 0.493px;
    opacity: 0.9;
  }

  &__images {
    position: relative;
    display: block;
    padding: 38px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    figure {
      margin: 0;
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      &:first-child {
        grid-area: 1 / 1 / 3 / 3;
        width: 540px;
        height: 420px;
      }
      &:nth-child(2) {
        grid-area: 1 / 3 / 2 / 4;
      }
      &:last-child {
        grid-area: 2 / 3 / 3 / 4;
      }

      img{
      border-radius: 4px;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }
}
