.AppWhatWeOffer {
  display: block;
  position: relative;
  background-color: var(--color-black);
  width: 100%;
  height: 100%;

  &__wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 60px 0;
    @media (max-width: 1024px) {
      padding: 20px 0;
      flex-direction: column;
    }
  }
}
