.AppContactUs {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--color-black);

  &__details {
    padding: 80px 0;
  }

  &__cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-bottom: 40px;
    @media (max-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__formWrap {
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      button {
        max-width: 300px;
        width: 100%;
        margin: 0;
        @media (max-width: 768px) {
          max-width: none;
        }
      }
      textarea {
        color: var(--color-white);
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--color-gray-900);
        background: var(--color-gray-900);
        padding: 14px 79px 145px 24px;
        &::placeholder {
          color: var(--color-white);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: var(--font-base);
          font-size: var(--font-size--md);
          font-style: normal;
          font-weight: var(--font-weight-normal);
          letter-spacing: 1px;
          opacity: 0.7;
        }
      }
      div {
        display: flex;
        flex-direction: row;
        gap: 60px;
        width: 100%;
        @media (max-width: 1280px) {
          gap: 30px;
        }

        > input {
          width: 100%;
          padding: 14px;
          align-items: center;
          border-radius: 4px;
          border: 1px solid var(--color-gray-900);
          background: var(--color-gray-900);
          width: 100%;
          @media (max-width: 1280px) {
            padding: 14px;
          }
          &::placeholder {
            color: var(--color-white);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: var(--font-base);
            font-size: var(--font-size--md);
            font-style: normal;
            font-weight: var(--font-weight-normal);
            letter-spacing: 1px;
            opacity: 0.7;
          }
        }
      }
    }
  }

  &__hours {
    max-width: 335px;
    width: 100%;
    padding: 20px;
    border-radius: 6px;
    background: var(--color-gray-900);
    position: relative;
    display: block;
    height: 100%;
    @media (max-width: 768px) {
      margin: 0 auto;
    }

    &--title {
      color: var(--color-white);
      font-feature-settings: 'case' on;
      font-family: var(--font-base);
      font-size: var(--font-size--xl);
      font-style: normal;
      font-weight: var(--font-weight-medium);
      line-height: normal;
      width: fit-content;
      text-align: start;
      padding: 0;
      padding-bottom: 20px;
      margin-bottom: 20px;

      &::before {
        content: '';
        position: absolute;
        width: 40%;
        bottom: 0;
        left: 0;
        height: 6px;
        border-radius: 8px;
        background-color: var(--color-red);
        z-index: 3;
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 2px;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        border-radius: 1px;
        background-color: #b3b3b3;
      }
    }

    &--workdays {
      display: grid;
      grid-template-columns: repeat(2, 7fr);
      justify-content: space-between;
      gap: 20px;
      color: var(--color-white);
      font-feature-settings: 'case' on;
      font-family: var(--font-base);
      font-size: var(--font-size--xl);
      font-weight: var(--font-weight-normal);
      line-height: normal;
      letter-spacing: 0.2px;
      opacity: 0.9;
    }
  }
}
