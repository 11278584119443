.AppPageTitle {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 200px;
  @media (max-width: 768px) {
    height: 140px;
  }

  figure {
    position: absolute;
    width: 100%;
    height: 200px;
    left: 0;
    top: 0;
    display: block;
    img {
      height: 100%;
    }
  }

  &__container {
    text-align: center;
    width: 100%;
    height: 100%;
    h3 {
      color: var(--color-white);
      font-feature-settings: 'case' on;
      font-family: var(--font-base);
      font-size: var(--font-size--lg-display);
      font-weight: var(--font-weight-normal);
      letter-spacing: 0.42px;
      margin: 0;
      top: 50%;
      transform: translateY(-50%);
      user-select: none;
    }
  }
}
