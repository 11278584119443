.AppHeaderLanguage__Wrapper {
    position: relative;
    width: 24px;
    height: 24px;
  }
  .AppHeaderLanguage {
    height: 24px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 10px;
    transform: translateX(-50%);
    transition: all 0.3s ease;
    padding-left: 0;
    ul {
      list-style: none;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 7px;
      border-radius: 8px;
      padding: 4px;
      padding-top: 0;
      li {
        a {
          display: block;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }
    &--Hovered {
      ul {
        padding: 5px 5px 5px;
        background-color: var(--color-white);
        border: 1px solid var(--color-gray-800);
      }
    }
  }
  