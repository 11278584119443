.AppDate {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding-bottom: 16px;

  span {
    color: var(--color-white);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: var(--font-base);
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: 24.548px;
    letter-spacing: 0.493px;
    opacity: 0.8;
  }
}
