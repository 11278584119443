.AppSectionTitle {
  padding-top: 72px;
  user-select: none;
  color: var(--color-white);
  text-align: center;
  font-family: var(--font-base);
  max-width: 700px;
  width: fit-content;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding-top: 42px;
  }
  &__heading {
    font-feature-settings: 'case' on;
    font-size: var(--font-size--md-display);
    font-style: normal;
    font-weight: var(--font-weight-medium);
    letter-spacing: 0.28px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      background-color: var(--color-red);
      max-width: 300px;
      width: 100%;
      height: 2px;
      border-radius: 8px;
    }
  }

  &__desc {
    font-style: normal;
    font-size: var(--font-size--xl);
    font-weight: var(--font-weight-normal);
    line-height: 159%;
    letter-spacing: 0.2px;
    opacity: 0.8;
  }
}
