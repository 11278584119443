.AppMenuList {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  @media (max-width: 1040px) {
    display: none;
  }

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 35px;
    @media (max-width: 1336px) {
      gap: 25px;
    }
    @media (max-width: 1280px) {
      gap: 15px;
    }

    li {
      list-style: none;
      font-feature-settings: 'case' on;
      font-family: var(--font-base);
      font-size: var(--font-size--lg);
      font-weight: var(--font-weight-normal);
      cursor: pointer;
      a {
        color: var(--color-white) !important;
      }
      &:active {
        &::after{
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: -20px;
          left: 0;
          background-color: var(--color-red);
        }
        a {
          color: var(--color-red) !important;
        }
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    @media (max-width: 1024px) {
      gap: 15px;
    }
    svg {
      cursor: pointer;
    }
  }

  &--vertical {
    > ul {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
      li {
        color: var(--color-white);
        font-feature-settings: 'case' on;
        font-family: var(--font-base);
        font-size: var(--font-size--lg);
        font-style: normal;
        font-weight: var(--font-weight-normal);
        line-height: normal;
        letter-spacing: 0.18px;
        opacity: 0.95;
        padding-left: 10px;
        &:active {
          &::after{
            display: none;
          }
          a {
            color: var(--color-red) !important;
          }
        }

        &::before {
          content: '';
          position: absolute;
          width: 4px;
          bottom: 0;
          top: 0;
          left: 0;
          height: 100%;
          border-radius: 8px;
          background-color: var(--color-red);
        }
      }
    }
  }
}
