.AppBlog {
  position: relative;
  display: block;
  background-color: var(--color-black);

  &__cards {
    padding: 90px 0 60px;
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    row-gap: 24px;
    column-gap: 20px;
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 3fr);
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      padding: 30px 0 60px;
    }
  }

  &__arrows{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: fit-content;
    margin: 0 auto;
    padding-bottom: 60px;
  }
}
