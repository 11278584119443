.AppCheckCar {
  position: relative;
  display: block;
  background-color: var(--color-black);
  padding-bottom: 20px;
  @media (max-width: 1024px) {
    padding-top: 20px;
  }

  &__container {
    background-color: var(--color-gray-900);
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    gap: 30px;
    padding: 50px 40px;
    padding-bottom: 0;
    margin-top: -80px;
    z-index: 2;
    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 15px;
      margin-top: 0;
    }
    @media (max-width: 768px) {
      gap: 12px;
    }
    span {
      white-space: nowrap;
      @media (max-width: 1280px) {
        white-space: wrap;
      }
    }
  }

  &__search {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    @media (max-width: 1024px) {
      padding-bottom: 20px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 12px;
      button {
        width: 100%;
      }
    }
  }
}
