.AppCarInner {
  position: relative;
  display: block;
  background-color: var(--color-black);
  width: 100%;
  height: 100%;
  padding: 50px 0;

  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &__images {
    width: 100%;
    height: 100%;
    max-width: 60%;
    gap: 20px;
    padding: 20px;
    background-color: var(--color-gray-900);
    border-radius: 4px;
    box-shadow: 0px 0px 10.52394px 0px rgba(0, 0, 0, 0.04);
    @media (max-width: 1024px) {
      max-width: 100%;
    }
  }

  &__details {
    border-radius: 4px;
    background: var(--color-gray-900);
    box-shadow: 0px 0px 10.52394px 0px rgba(0, 0, 0, 0.04);
    padding: 20px;
    color: var(--color-white);
    max-width: 100%;
    overflow: auto;
    @media (max-width: 1280px) {
      padding: 10px;
    }
    @media (max-width: 1024px) {
      width: 100%;
    }

    &--title {
      color: var(--grayscale-off-white, #fcfcfc);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: FiraGO;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-bottom: 20px;
      margin: 0;
    }

    &--points {
      display: flex;
      flex-direction: row;
      gap: 28px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(217, 217, 217, 0.3);
      margin-bottom: 20px;
      span {
        color: var(--color-white);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: var(--font-base);
        font-size: var(--font-size-xl);
        font-style: normal;
        font-weight: var(--font-weight-normal);
        opacity: 0.8;
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: calc(100% + 10px);
          transform: translateY(-50%);
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background-color: var(--color-red);
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }

    &--code {
      display: block;
      color: var(--color-white);
      font-family: var(--font-base);
      font-size: var(--font-size-lg);
      opacity: 0.8;
      padding-bottom: 18px;
    }

    &--price {
      color: var(--color-red);
      font-family: var(--font-base);
      font-size: var(--font-size--md-display);
      font-style: normal;
      font-weight: var(--font-weight-medium);
      line-height: 159%;
      letter-spacing: 0.48px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(217, 217, 217, 0.3);
    }

    &--info {
      display: grid;
      grid-template-columns: repeat(2, 11fr);
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      padding: 0;
      span {
        white-space: nowrap;
        font-feature-settings: 'clig' off, 'liga' off;
        color: var(--color-white);
        font-family: var(--font-base);
        font-style: normal;
        font-weight: var(--font-weight-normal);
        font-size: var(--font-size-md);
        &:nth-child(odd) {
          opacity: 0.8;
        }
      }
    }

    &--additional {
      color: var(--color-white);
      font-family: var(--font-base);
      font-feature-settings: 'case' on;
      font-size: var(--font-size-xl);
      font-style: normal;
      line-height: normal;
      font-weight: var(--font-weight-normal);
      opacity: 0.95;
      white-space: nowrap;
      padding: 20px;
      padding-left: 0;
    }
  }
}
