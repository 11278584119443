.AppHero {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &__figure {
    height: 60vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
    margin: 0;
    @media (max-width: 420px) {
      height: 100vh;
    }
  }
  figure {
    img {
      height: 100% !important;
    }
  }
}
