* {
    box-sizing: border-box;
    &:before,
    &:after {
      box-sizing: border-box;
    }
  }
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    position: relative;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    outline: none;
  }
  @-ms-viewport {
    width: device-width;
  }
  article,
  aside,
  dialog,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
    position: relative;
  }
  html {
    font-family: var(--ffbase);
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(var(--ffbase), 0);
  }
  body {
    margin: 0;
    color: var(--cbase);
    font-family: var(--ffbase);
    font-weight: 400;
    text-align: left;
    line-height: 1.5;
  }
  [tabindex='-1'] {
    &:focus {
      &:not(:focus-visible) {
        outline: 0 !important;
      }
    }
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    border-color: var(--cline);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 1.3;
  }
  p {
    margin-top: 0;
    margin-bottom: 10px;
  }
  time {
    display: block;
  }
  ul {
    margin: 10px 10px 10px 25px;
    padding: 0;
    list-style: disc;
    ul {
      margin: 10px 10px 10px 25px;
      padding: 0;
      list-style: disc;
    }
  }
  ol {
    margin: 10px 10px 10px 25px;
    padding: 0;
    list-style-type: decimal;
    ol {
      margin: 10px 10px 10px 25px;
      padding: 0;
      list-style-type: decimal;
    }
  }
  abbr {
    &[title],
    &[data-original-title] {
      text-decoration: underline;
      text-decoration: underline dotted;
      text-decoration-skip-ink: none;
      border-bottom: 0;
      cursor: help;
    }
  }
  address {
    margin-bottom: 0;
    font-style: normal;
    line-height: inherit;
  }
  dl {
    margin-top: 0;
    margin-bottom: 0;
  }
  dt {
    font-weight: 400;
  }
  dd {
    margin-bottom: 0;
    margin-left: 0;
  }
  blockquote {
    margin: 0;
  }
  b,
  strong {
    font-weight: 700;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    position: relative;
    vertical-align: baseline;
    font-size: 75%;
    line-height: 0;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  a {
    color: var(--color-white) !important;
    text-decoration: none !important;
    background-color: transparent;
    &:hover {
      color: var(--cbase);
    }
    &:not([href]) {
      color: inherit;
      text-decoration: none;
      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
  }
  pre,
  code,
  kbd,
  samp {
    font-size: 14px;
    font-family: var(--ffbase);
  }
  pre {
    margin-top: 0;
    margin-bottom: 0;
    overflow: auto;
  }
  figure {
    margin: 0;
  }
  img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
    border-style: none;
  }
  svg {
    overflow: hidden;
    vertical-align: middle;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  caption {
    padding-top: 0;
    padding-bottom: 0;
    color: var(--cbase);
    text-align: left;
    caption-side: bottom;
  }
  th {
    text-align: inherit;
  }
  label {
    display: inline-block;
    margin-bottom: 5px;
  }
  input,
  label,
  select,
  button,
  textarea {
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: middle;
    font-size: 14px;
    font-family: var(--ffbase);
    line-height: 1;
    white-space: normal;
    background: none;
    box-shadow: none;
    outline: none;
  }
  input {
    &[type='search'] {
      outline-offset: -2px;
      -webkit-appearance: none;
      &::-webkit-search-decoration {
        -webkit-appearance: none;
      }
    }
    &[type='radio'],
    &[type='checkbox'] {
      box-sizing: border-box;
      padding: 0;
    }
    &[type='date'],
    &[type='time'],
    &[type='datetime-local'],
    &[type='month'] {
      -webkit-appearance: listbox;
    }
    &[type='number'] {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        height: auto;
      }
    }
    &[type='reset'],
    &[type='button'],
    &[type='submit'],
    &[type='checkbox'],
    &[type='radio'] {
      width: auto;
      box-sizing: border-box;
      overflow: visible;
    }
    &:focus {
      outline: 0;
    }
  }
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: inherit;
  }
  textarea {
    resize: vertical;
    overflow: auto;
  }
  input,
  button {
    overflow: visible;
  }
  select,
  button {
    text-transform: none;
  }
  select {
    word-wrap: normal;
  }
  button {
    border-radius: 0;
    -webkit-appearance: button;
    &[type='button'],
    &[type='reset'],
    &[type='submit'] {
      -webkit-appearance: button;
      &::-moz-focus-inner {
        padding: 0;
        border-style: none;
      }
    }
    &:focus {
      outline: none;
    }
  }
  button,
  select {
    width: auto;
    box-sizing: border-box;
    overflow: visible;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0;
    color: inherit;
    font-size: 14px;
    line-height: inherit;
    white-space: normal;
  }
  progress {
    vertical-align: baseline;
  }
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  output {
    display: inline-block;
  }
  summary {
    display: list-item;
    cursor: pointer;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none !important;
  }
  