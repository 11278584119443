.AppAdminProfile {
  margin-left: 240px;
  &__user {
    position: relative;
    width: 460px;
    background-color: var(--color-gray-900);
    text-align: center;
    margin: 20px auto;
    padding: 20px;
    &--title {
      color: #fff;
      font-family: Helvetica Neue LT GEO;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 144.2%;
      letter-spacing: 0.2px;
      margin-bottom: 20px;
    }
    &--Details {
      color: #fff;
      display: grid;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      row-gap: 20px;
      grid-template-columns: repeat(2, 2fr);
      max-width: fit-content;
      margin: 0 auto;
    }
    &--form {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      margin: 0 auto;
      max-width: 280px;
      margin-bottom: 0 !important;
      padding-bottom: 40px;
      input {
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.3);
      }
    }
  }
}
