.Dialog {
  display: block;
  position: absolute;
  top: -100px;
  left: -55px;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  border: 10px solid var(--color-gray-900);
  color: var(--color-white);
  background-color: rgba(19, 20, 21, 0.9);
  z-index: 99;

  &__content {
    background-color: var(--color-gray-900);
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    padding: 40px;
    border-radius: 8px;
    border: 1px solid var(--color-red);
    overflow-y: auto;
    height: 100%;
  }

  &--close {
    position: absolute;
    right: 20px;
    top: 10px;
  }
}
