.AppHeaderMobileMenu {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100vw);
  height: calc(100vh);
  overflow-y: scroll;
  background-color: var(--color-black);
  z-index: 99;
  margin-top: -20px;
  margin-left: -20px;
  overflow: hidden;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    gap: 10px;
    li {
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size--xl);
      color: var(--color-white);
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 99;
  }
}
