.AppAdminHomeFilter{
    position: relative;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    background: var(--color-gray-900);
    
    &__select{
        padding: 20px;
        display: flex;
        flex-direction: row;
        gap: 25px;
        max-width: 700px;

    }
}