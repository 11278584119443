.AppBlogInner {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--color-black);
  padding-bottom: 90px;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    margin-top: -100px;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
}
