Dialog Container .dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

dialog {
  width: 1080px;
}

/* Child Component */
.child-component {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Additional styles for the child component */
/* You can customize this based on your needs */
.child-component h2 {
  margin-bottom: 10px;
}

.child-component p {
  color: #333;
}

.cell-green {
  background-color: #bae0bd;
}
