.AppAdminHeader{
    position: fixed;
    z-index: 5;
    width: 100%;
    height: 100px;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-gray-900);
    border-bottom: 1px solid #DB2D2E;
    padding: 29px 25px 29px 16px;
}