.AppAboutUs {
  position: relative;
  display: block;
  background-color: var(--color-black);

  &__content {
      color: var(--color-white);
      text-align: center;
      font-family: var(--font-base);
      font-size: var(--font-size--xl);
      font-weight: var(--font-weight-normal);
      font-style: normal;
      line-height: 206%;
      letter-spacing: 0.2px;
      padding: 50px 0;
    p {
      margin: 0;
    }
  }
}
