.AppContactCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 18px;
  background-color: var(--color-gray-900);
  border-radius: 4px;
  max-width: 335px;
  max-height: 308px;
  padding: 40px 16px;
  width: 100%;
  height: 100%;
  @media (max-width: 1024px) {
    max-width: none;
  }

  &__icon {
    margin: 0;
  }

  &__title {
    color: var(--color-white);
    text-align: center;
    font-feature-settings: 'case' on;
    font-size: var(--font-size--xl);
    font-family: var(--font-base);
    letter-spacing: 0.2px;
    font-weight: var(--font-weight-medium);
    line-height: 159%; /* 31.8px */
    margin: 0;
    padding-bottom: 18px;
    &::after {
      content: '';
      position: absolute;
      width: 120%;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      background-color: var(--color-red);
      border-radius: 8px;
    }
  }

  &__desc {
    color: var(--color-white);
    text-align: center;
    font-feature-settings: 'case' on;
    font-size: var(--font-size--xl);
    font-family: var(--font-base);
    letter-spacing: 0.2px;
    font-weight: var(--font-weight-normal);
    line-height: 182%; /* 36.4px */
  }
}
