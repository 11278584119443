form {
  display: grid;
  grid-template-columns: 2fr 2fr;
  row-gap: 48px;
  column-gap: 24px;
  margin-bottom: 68px !important;
}
.AppCalculator {
  &__select {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
  }
  &__label {
    color: var(--color-white);
    font-feature-settings: 'case' on;
    font-family: var(--font-base);
    font-size: var(--font-size--md);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: 159%;
    letter-spacing: 0.16px;
  }
}
