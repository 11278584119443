.AppFindYourOffer {
  position: relative;
  display: block;
  background-color: var(--color-black);

  &__container {
    margin: 0 auto;
    background-color: var(--color-gray-900);
    width: 100%;
    padding-bottom: 80px;
  }

  &__selectWrap {
    display: grid;
    column-gap: 60px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    row-gap: 70px;
    grid-template-columns: repeat(3, 2fr);
    max-width: 800px;
    padding: 70px 0;
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 3fr);
      }
  }
}
