.AppArticleTitle {
  color: var(--color-white);
  font-family: var(--font-base);
  font-style: normal;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 50px;
  @media (max-width: 1024px) {
    padding-bottom: 22px;
    gap: 8px;
  }
  @media (max-width: 420px) {
    padding-bottom: 12px;
    gap: 3px;
  }

  h3 {
    font-feature-settings: 'case' on;
    font-size: var(--font-size--md-display);
    font-weight: var(--font-weight-medium);
    line-height: normal;
    letter-spacing: 0.28px;

    &::before {
      content: '';
      position: absolute;
      width: 4px;
      bottom: 0;
      top: 0;
      left: -15px;
      height: 100%;
      border-radius: 8px;
      background-color: var(--color-red);
    }
  }

  span {
    font-size: var(--font-size--xl);
    font-style: normal;
    font-weight: 400;
    line-height: 159%;
    letter-spacing: 0.2px;
    opacity: 0.8;
  }
}
