.AppSocials {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  @media (max-width: 1280px) {
    gap: 15px;
  }
  svg {
    @media (max-width: 1280px) {
      width: 20px;
    }
    @media (max-width: 768px) {
      width: 15px;
    }
  }
}
