.AppLoginForm {
  display: block;
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  background-color: var(--color-black);

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    figure {
      width: 250px;
      height: 50px;
      padding-bottom: 60px;
    }
  }

  &__title {
    color: var(--color-white);
    text-align: center;
    font-feature-settings: 'case' on;
    font-family: var(--font-base);
    font-size: var(--font-size--md-display);
    font-style: normal;
    font-weight: var(--font-weight-medium);
    line-height: normal;
    letter-spacing: 0.28px;
    padding-bottom: 15px;
    user-select: none;
  }

  &__subtitle {
    color: var(--color-white);
    text-align: center;
    font-family: var(--font-base);
    font-size: var(--font-size--md);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: 170.5%; /* 34.1px */
    letter-spacing: 0.2px;
    padding-bottom: 40px;
    opacity: 0.8;
    user-select: none;
  }

  &__container {
    max-width: 500px;
    position: relative;
    display: block;
    margin: 0 auto;
    padding: 100px 0 60px;
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      max-width: 300px;
      margin: 0 auto;
      div {
        margin: 0;
        padding: 0;
      }
      label {
        color: var(--color-white);
        font-feature-settings: 'case' on;
        font-family: var(--font-base);
        font-size: var(--font-size--md);
        font-style: normal;
        font-weight: var(--font-weight-normal);
        line-height: 159%;
        letter-spacing: 0.16px;
        padding-bottom: 8px;
        span {
          padding-left: 2px;
        }
        user-select: none;
      }
      input {
        width: 100%;
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.3);
      }
    }
  }
}
