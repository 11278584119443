.Auctions {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 40px;
  padding-top: 20px;
  overflow: auto;

  &__new {
    width: fit-content;
    padding-left: 40px;
    padding-top: 20px;
  }

  &__table {
    border-collapse: collapse;
    color: var(--color-white);
    border-collapse: separate;
    padding-bottom: 40px;
    th,
    td {
      max-width: 250px;
      width: fit-content;
      text-align: center;
      padding: 15px;
    }
    thead {
      background-color: var(--color-gray-900);
      tr {
        border-radius: 4px;
        th {
          font-size: var(--font-size--xl);
        }
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}
