.AppBlogCard {
  position: relative;
  display: grid;
  background-color: var(--color-gray-900);
  box-shadow: 0px 0px 10.52394px 0px rgba(0, 0, 0, 0.04);
  border-radius: 21px;
  user-select: none;

  figure {
    position: relative;
    display: block;
    width: 100%;
    height: 335px;
    padding: 20px;
    margin: 0;

    a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 12px;
      img {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
        transition: all 0.5s ease;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    @media (max-width: 1280px) {
      height: 180px;
    }

    @media (max-width: 1024px) {
      height: 300px;
    }
  }
  &__details {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--color-gray-900);
    color: var(--color-white);
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;
    border-radius: 21px;
    @media (max-width: 768px) {
      gap: 8px;
    }
  }

  &__title {
    color: var(--color-white);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: var(--font-base);
    font-size: var(--font-size--xl);
    font-style: normal;
    font-weight: var(--font-weight-medium);
    line-height: 30px;
    letter-spacing: 0.4px;
    padding-bottom: 16px;
    margin: 0;
  }

  &__link {
    width: fit-content;
    text-align: start;
  }

  &__desc {
    color: #a0a3bd;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: var(--font-base);
    font-size: var(--font-size--md);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: 24.548px;
    letter-spacing: 0.493px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: start;
  }
}
