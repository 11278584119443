.BecomeDealer {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--color-black);

  &__formWrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 70px;
    @media (max-width: 768px) {
      flex-direction: column;
      padding-top: 30px;
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      div {
        label {
          color: var(--color-white);
        }
      }
      button {
        max-width: 300px;
        width: 100%;
        margin: 0 auto;
        @media (max-width: 768px) {
          max-width: none;
        }
      }
      textarea {
        color: var(--color-white);
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--color-gray-900);
        background: var(--color-gray-900);
        padding: 14px 79px 145px 24px;
        &::placeholder {
          color: var(--color-white);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: var(--font-base);
          font-size: var(--font-size--md);
          font-style: normal;
          font-weight: var(--font-weight-normal);
          letter-spacing: 1px;
          opacity: 0.7;
        }
      }
      div {
        display: flex;
        flex-direction: row;
        gap: 60px;
        width: 100%;
        @media (max-width: 1280px) {
          gap: 30px;
        }

        > input {
          width: 100%;
          padding: 14px;
          align-items: center;
          border-radius: 4px;
          border: 1px solid var(--color-gray-900);
          background: var(--color-gray-900);
          width: 100%;
          @media (max-width: 1280px) {
            padding: 14px;
          }
          &::placeholder {
            color: var(--color-white);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: var(--font-base);
            font-size: var(--font-size--md);
            font-style: normal;
            font-weight: var(--font-weight-normal);
            letter-spacing: 1px;
            opacity: 0.7;
          }
        }
      }
    }
  }

  &__errorMessage{
    color: var(--color-white);
    text-align: center;
    margin: 0;
    width: 100%;
    margin-top: -25px;
  }
}