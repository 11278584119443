:lang(ka) {
  h1 {
    font-family: var(--font-geo), sans-serif !important;
  }
}
body {
  color: var(--color-gray-500);
  font-size: var(--font-size--lg);
  line-height: var(--line-height--lg);
  font-weight: var(--font-weight-normal);
  font-family: var(--font-base), sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }
}
.swiper-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  svg {
    height: 200px;
    @media (max-width: 1440px) {
      height: 120px;
    }
    @media (max-width: 768px) {
      height: 60px;
    }

    path {
      stroke: var(--color-gray-100);
    }
  }
}
.page-link {
  color: black !important;
}
.page-item.active .page-link {
  background-color: var(--color-red);
  border-color: var(--color-red);
}
.admin-layout {
  margin-top: 100px;
  margin-left: 55px;
  height: calc(100% - 100px);
  min-height: 90vh;
  background-color: black;
  box-sizing: border-box;
}

.dealer-layout {
  margin-top: 40px;
  margin-left: 55px;
  height: calc(100% - 100px);
  min-height: 90vh;
  background-color: black;
  box-sizing: border-box;
}
.new-button {
  width: fit-content;
  padding-left: 40px;
  padding-top: 20px;
}
.image-gallery-play-button {
  display: none;
}
.image-gallery-left-nav,
.image-gallery-right-nav {
  svg {
    height: 80px !important;
  }
}
.image-gallery-icon {
  &:hover {
    color: var(--color-red) !important;
  }
}
.image-gallery-fullscreen-button {
  svg {
    height: 20px !important;
    width: 20px !important;
  }
}
.image-gallery-image {
  object-fit: cover !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 550px !important;
}
.image-gallery-content.fullscreen {
  .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px) !important;
  }
}
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-bottom .thumbnails-swipe-horizontal {
  height: 100% !important;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: 2px solid #db2d2e !important;
}
.image-gallery-thumbnail {
  &:hover {
    border: 2px solid #db2d2e !important;
  }
}
.swiper-button-prev {
  left: 20px;
}
.swiper-button-next {
  right: 20px;
}

.swiper-button-disabled {
  opacity: 0.5;
}

input[type='text'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='range'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='week'] {
  height: 48px;
  width: 100%;
  padding: 12px 24px;
  border-radius: 4px;
  border: 1px solid var(--color-white);
  color: var(--color-white);
  font-family: var(--font-base);
}
.react-select-container {
  color: var(--color-white);
  position: relative;
  width: 100%;
  height: 48px;
  .contor {
    background-color: var(--color-gray-900);
  }
}
// mobile seasons slider
.AppMobileSeasons__Slider__Pagination {
  position: absolute !important;
  bottom: 20px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  z-index: 10 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .swiper-pagination-bullet {
    background: var(--color-white);
  }

  .swiper-pagination-bullet-active {
    background: var(--color-white);
  }
}
